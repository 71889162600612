/* eslint-disable max-len */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
import React, { useEffect, useState, useRef } from 'react'
import { useIntl, navigate } from 'gatsby-plugin-intl'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useQueryParam, StringParam } from 'use-query-params'
import './style.css'
import { Input } from '@material-ui/core'
import Slider from '../Forms/Slider/Slider'
import arrR from '../../assets/icons/arrR.svg'
import search from '../../assets/icons/searchIcon.svg'
import mapProj from '../../assets/icons/mapProj.svg'
import Button from '../Forms/Button/Button'
import MetaTags from '../Header/MetaTags'

function CosaFacciamo({ data, projects, categories }) {
  const breakpoints = useBreakpoint()
  const [queryFilter, setQueryFilter] = useQueryParam('filter', StringParam)
  const intl = useIntl()

  const searchParagraph = data[intl.locale].acf.searchData
  const sliderCosaFaciamo = data[intl.locale].acf.homepageSlider
  const { CardsCosaFacciamo } = data[intl.locale].acf
  const { yoast_head_json } = data[intl.locale]
  const acfCategorys = categories[intl.locale]
  const [acfProjects, setAcfProjects] = useState(projects[intl.locale])
  const acfProjectsOriginal = projects[intl.locale]
  const [acfProjectsNumToRender, setAcfProjectsNumToRender] = useState(8)
  const [acfProjectsFilteredBy, setAcfProjectsFilteredBy] = useState([])
  const [input, setInput] = useState('')
  const inputRef = useRef(null)

  const getCaegoryId = name => {
    let res = null
    acfCategorys.forEach(cat => {
      if (cat.name.toUpperCase() === name.toUpperCase()) {
        res = cat.id
      }
    })

    return res
  }

  const handleFilter = id => {
    inputRef.current.value = ''
    const tmp = acfProjectsFilteredBy

    if (findOnCategorySelected(id)) {
      if (tmp.length <= 1) {
        setAcfProjects(acfProjectsOriginal)
        setAcfProjectsFilteredBy([])
        setAcfProjectsNumToRender(8)
        return
      }
      tmp.splice(tmp.indexOf(id), 1)

      pureFilter()
      setAcfProjectsFilteredBy(tmp)
      return
    }

    tmp.push(id)
    setAcfProjectsFilteredBy(tmp)

    pureFilter()
  }

  const pureFilter = () => {
    const res = acfProjectsOriginal.filter(proj => {
      let res1 = false
      proj.categories.forEach(element => {
        if (findOnCategorySelected(element.id)) res1 = true
      })
      return res1
    })
    if (res.length > 0) setAcfProjects(res)
  }

  const findCategory = categories => {
    const res = []
    acfCategorys.forEach(element => {
      if (categories.find(cat => cat.id === element.id)) res.push(element.name)
    })

    return res || ''
  }

  const findOnCategorySelected = id => {
    if (acfProjectsFilteredBy.find(cat => cat === id)) return true
    return false
  }

  const handleChange = e => {
    setInput(e)
    setAcfProjectsFilteredBy([])
    if (e.length < 1) {
      setAcfProjects(acfProjectsOriginal)
      return
    }
    const res = acfProjectsOriginal.filter(proj =>
      proj.title.rendered.toUpperCase().includes(e.toUpperCase())
    )

    setAcfProjects(res)
  }

  const projectsOrdered = acfProjects.sort((a, b) => a.acf.projectOrder?.order - b.acf.projectOrder?.order);
  
  useEffect(() => {
    let tmpFilter = ''
    if (queryFilter) {
      if (
        queryFilter.toUpperCase() === 'sociale e beneficenza'.toUpperCase() ||
        queryFilter.toUpperCase() === 'social and charity'.toUpperCase() ||
        queryFilter.toUpperCase() === 'sociales y caritativos'.toUpperCase()
      )
        tmpFilter = queryFilter
      if (
        queryFilter.toUpperCase() === 'ricerca scientifica'.toUpperCase() ||
        queryFilter.toUpperCase() === 'scientific research'.toUpperCase() ||
        queryFilter.toUpperCase() === 'investigación científica'.toUpperCase()
      )
        tmpFilter = queryFilter
      if (
        queryFilter.toUpperCase() === 'ARTE E CULTURA'.toUpperCase() ||
        queryFilter.toUpperCase() === 'art and culture'.toUpperCase() ||
        queryFilter.toUpperCase() === 'arte y Cultura'.toUpperCase()
      )
        tmpFilter = queryFilter
      // if (queryFilter === 'SOCIALE E BENEFICENZA') tmpFilter = 'sociale';
      const catId = getCaegoryId(tmpFilter)
      catId && handleFilter(catId)
    }

    /// SORTING
    const mylist = document.getElementById('cosaFacciamo')
    const divs = mylist.children
    const listitems = []
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i))
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id')
      const compB = b.getAttribute('id')
      return compA < compB ? -1 : compA > compB ? 1 : 0
    })
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i])
    }
  }, [])

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="cosaFacciamo">
        {sliderCosaFaciamo.slider.length > 0 && (
          <div id={sliderCosaFaciamo.positionSlider}>
            <Slider
              data={sliderCosaFaciamo.slider}
              ctaGoTo="true"
              goToVisibile={false}
            />
          </div>
        )}

        {searchParagraph.searchTitle && searchParagraph.searchContent && (
          <div id={searchParagraph.positionSearch} className="ricerca">
            <div className="cosaFacciamo_barraRicerca">
              <div className="cosaFacciamo_searchParagraph_container">
                <h2
                  className="cosaFacciamo_searchParagraph_title"
                  style={{ maxWidth: !breakpoints.md ? 790 : 330 }}
                >
                  {searchParagraph.searchTitle}
                </h2>
                <div
                  className="cosaFacciamo_searchParagraph_desc"
                  style={{ maxWidth: !breakpoints.md ? 790 : 330 }}
                  dangerouslySetInnerHTML={{
                    __html: searchParagraph.searchContent,
                  }}
                />
              </div>
              <div className="cosaFacciamo_barra">
                <div
                  className="cosaFacciamo_iconaRicerca"
                  style={{
                    marginLeft: breakpoints.md ? 5 : 0,
                    marginRight: breakpoints.md ? 10 : 0,
                    padding: breakpoints.md ? 10 : 15,
                    width: breakpoints.md ? '20px' : '34px',
                    height: breakpoints.md ? '20px' : '34px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                  }}
                >
                  <img
                    onClick={() => setAcfProjectsNumToRender(8)}
                    alt="search"
                    src={search}
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                </div>
                <div className="cosaFacciamo_textBox">
                  <Input
                    onChange={e => handleChange(e.target.value)}
                    inputRef={inputRef}
                    fullWidth
                    disableUnderline
                    placeholder={intl.formatMessage({
                      id: 'placeholder_project_searchbar',
                    })}
                    classes={{
                      height: 40,
                      fontSize: 24,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="cosaFacciamo_ricercaBody">
              <div className="cosaFacciamo_ricercaCategorys">
                {acfCategorys &&
                  acfCategorys.map((cat, index) => (
                    <div
                      key={index}
                      className={
                        findOnCategorySelected(cat.id)
                          ? 'cosaFacciamo_ricercaSingleCategorySelected'
                          : 'cosaFacciamo_ricercaSingleCategory'
                      }
                      onClick={() => {
                        handleFilter(cat.id)
                        // setQueryFilter(null) makes page refresh
                      }}
                    >
                      {cat.name}
                    </div>
                  ))}
              </div>
              <div className="cosaFacciamo_ricercaProjects">
                {projectsOrdered &&
                  projectsOrdered.map((proj, index) => {
                    if (!proj.acf.projectPreview.projectImage) return null
                    if (!proj.acf.projectPreview.projectExcerpt) return null
                    if (index + 1 <= acfProjectsNumToRender) {
                      return (
                        <div className="cosaFacciamo_ricercaSingleProject">
                          <div>
                            <div
                              className="cosaFacciamo_singleProjectIMG"
                              onClick={() => navigate(`/${proj.slug}`)}
                            >
                              <div
                                className="cosaFacciamo_singleProjectPlace"
                                style={{ width: breakpoints.md ? 303 : 368 }}
                              >
                                <img
                                  src={mapProj}
                                  alt="mapICON"
                                  style={{
                                    height: 25,
                                    width: 25,
                                    marginRight: 10,
                                  }}
                                />
                                {proj.acf.map.cities?.map((city) => { return city.city }).join(', ')}
                              </div>
                              <img
                                alt="projectIMG"
                                src={proj.acf.projectPreview.projectImage}
                                style={{ objectFit: 'cover' }}
                              />
                            </div>

                            <h2 className="cosaFacciamo_singleProjectTitle">
                              {proj.title ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: proj.title.rendered,
                                  }}
                                />
                              ) : (
                                'No title'
                              )}
                            </h2>
                            <div className="cosaFacciamo_singleProjectContent">
                              {proj.acf.projectPreview.projectExcerpt
                                ? proj.acf.projectPreview.projectExcerpt
                                : 'No desc'}
                            </div>
                            <div
                              className="cosaFacciamo_singleProjectBtn"
                              onClick={() => navigate(`/${proj.slug}`)}
                            >
                              {intl.formatMessage({ id: 'btn_leggi_tutto' })}
                              <img
                                alt="showlessIMG"
                                src={arrR}
                                style={{
                                  width: '15px',
                                  height: '15px',
                                }}
                              />
                            </div>
                          </div>
                          <div className="cosaFacciamo_singleProjectBottom">
                            {findCategory(proj.categories).map(
                              (projCat, index) => {
                                const res = acfCategorys.filter(
                                  cat => cat.name === projCat
                                )

                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <img
                                      src={
                                        res[0].acf['Category Icon'].categoryIcon
                                      }
                                      alt="iconProject"
                                      style={{
                                        width: 30,
                                        marginRight: 7,
                                        marginLeft: 7,
                                      }}
                                    />
                                    {index + 1 < proj.categories.length && (
                                      <div
                                        style={{
                                          borderLeft: '1px solid white',
                                          width: 1,
                                          height: '35px',
                                        }}
                                      />
                                    )}
                                  </div>
                                )
                              }
                            )}
                            {findCategory(proj.categories).map(
                              (projCat, index) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {index > 0 ? (
                                    <span style={{ marginLeft: 5 }}>
                                      {projCat.substring(0, 15)}...
                                    </span>
                                  ) : (
                                    projCat
                                  )}
                                  {index + 1 < proj.categories.length && ' , '}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
            <div className="ricerca_Btns">
              {acfProjectsNumToRender > 8 && (
                <div className="ricerca_showLess">
                  <img
                    onClick={() => setAcfProjectsNumToRender(8)}
                    alt="showlessIMG"
                    src={arrR}
                    style={{
                      padding: 6,
                      width: '16px',
                      height: '16px',
                      border: '1px solid black',
                      borderRadius: '50%',
                      transform: 'rotate(-90deg)',
                    }}
                  />
                </div>
              )}
              {acfProjectsNumToRender < acfProjects.length && (
                <div className="ricerca_showMore">
                  <Button
                    label={intl.formatMessage({
                      id: 'cosa_facciamo_carica_altri_progetti',
                    })}
                    handleClick={() =>
                      setAcfProjectsNumToRender(
                        acfProjectsNumToRender + 3 < acfProjects.length
                          ? acfProjectsNumToRender + 3
                          : acfProjects.length
                      )
                    }
                    style={{
                      backgroundColor: '#D75F2B',
                      color: 'white',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {CardsCosaFacciamo.cardsCosaFacciamo.length > 0 && (
          <div
            id={CardsCosaFacciamo.positionCards}
            className="cosaFacciamo_cardsContainer"
          >
            {CardsCosaFacciamo.cardsCosaFacciamo &&
              CardsCosaFacciamo.cardsCosaFacciamo.map((card, index) => {
                if (!card.cardBackgroundImage) return null
                return (
                  <div
                    className="cosaFacciamo_card"
                    style={{
                      background: `url(${card.cardBackgroundImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  >
                    <div
                      className="cosaFacciamo_cardOverlay"
                      style={{
                        backgroundColor:
                          index === 0
                            ? '#003F6E'
                            : index === 1
                            ? '#D75F2B'
                            : index === 2
                            ? '#A6322E'
                            : 'white',
                        opacity: '0.2',
                      }}
                    />
                    <div
                      className="cosaFacciamo_cardContent"
                      style={{
                        backgroundColor:
                          index === 0
                            ? '#003F6E'
                            : index === 1
                            ? '#D75F2B'
                            : index === 2
                            ? '#A6322E'
                            : 'white',
                        opacity: '0.8',
                      }}
                    >
                      <div className="cosaFacciamo_cardContentTxt">
                        {card.cardDescriptionParagraph}
                      </div>

                      <div className="cosaFacciamo_cardContentAuthor">
                        <div className="interline" />
                        <div className="cosaFacciamo_cardContentAuthorTxt">
                          {card.cardAuthor}{' '}
                        </div>
                        <div className="interline" />
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </>
  )
}

export default CosaFacciamo
