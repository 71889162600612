import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import CosaFacciamo from '../components/CosaFacciamo/CosaFacciamo';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function CosaFacciamoPage({pageContext}) {
  const {
    data,
    projects,
    categories,
  } = pageContext;

  return (
    <MainLayout>
      <CosaFacciamo data={data} projects={projects} categories={categories} />
    </MainLayout>
  );
}

export default injectIntl(CosaFacciamoPage);
